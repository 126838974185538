var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.color}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"bold","line-height":"2em"}},[_vm._v(_vm._s(_vm.text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.course.title))]),(_vm.status === 'returned')?_c('v-list-item-subtitle',{staticStyle:{"font-weight":"bold"}},[_vm._v("Returned to the student.")]):_vm._e(),_vm._l((_vm.approvals),function(ref){
var _id = ref._id;
var category = ref.category;
var date = ref.date;
var name = ref.name;
return _c('v-list-item-subtitle',{key:_id},[_c('div',{staticStyle:{"font-weight":"bold","width":"270px"}},[_vm._v(_vm._s(category + ' Approval' + (name ? ' (' + name + ')' : ''))+": ")]),_c('div',[_c('v-icon',{attrs:{"color":date == null ? 'info' : 'success',"small":""}},[_vm._v("fal fa-"+_vm._s(date == null ? 'question' : 'check')+"-circle")]),_vm._v(" "+_vm._s(date == null ? 'Pending' : 'Approved ' + _vm.stringFormatDate(date))+" ")],1)])})],2),(_vm.isRecords && _vm.approvals.filter(function (ref) {
	var grade = ref.grade;

	return grade != null;
}).length > 0)?_c('v-list-item-action',[_vm._v(" Grade: "+_vm._s(_vm.approvals.find(function (ref) {
	var grade = ref.grade;

	return grade != null;
}).grade)+" ")]):_vm._e()],1)],1),(_vm.isApprover && ['submitted', 'pending', 'approved'].includes(_vm.status))?_c('v-card-actions',[(_vm.isRecords)?[(_vm.status !== 'submitted')?_c('v-btn',{staticStyle:{"height":"56px"},attrs:{"disabled":!_vm.instructorApproved || !_vm.advisorApproved,"color":"success"},on:{"click":function($event){return _vm.approve('Records')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-check-circle")]),(_vm.instructorApproved && _vm.advisorApproved)?_c('span',[_vm._v("Mark"),_c('br'),_vm._v("Processed")]):_c('span',[_vm._v("Awaiting"),_c('br'),_vm._v(_vm._s(_vm.action === 'add' ? 'Approval' : 'Acknowledgement'))])],1):_vm._e(),_c('v-spacer'),(['submitted', 'pending', 'approved'].includes(_vm.status) || !_vm.instructorApproved || !_vm.advisorApproved)?_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","outlined":"","small":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-pencil")]),_vm._v(" Edit ")],1)]}}],null,false,949040716)},[_c('v-list',{attrs:{"dense":""}},[(_vm.status !== 'processed')?_c('v-list-item',{on:{"click":_vm.returnToStudent}},[_c('v-list-item-title',[_vm._v("Return to Student")])],1):_vm._e(),(_vm.isRecords && _vm.status === 'submitted')?_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-title',[_vm._v("Change Approving Advisor")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-chevron-right")])],1)],1)]}}],null,false,973796401)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.advisors),function(ref){
var pidm = ref.pidm;
var name = ref.name;
var code = ref.code;
return _c('v-list-item',{key:pidm,attrs:{"disabled":pidm === _vm.advisorPidm},on:{"click":function($event){return _vm.updateAdvisor(pidm)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',[_vm._v("Advisor Code: "+_vm._s(code))])],1)],1)}),1)],1):_vm._e(),(_vm.isRecords && !_vm.instructorApproved)?_c('v-list-item',{on:{"click":function($event){return _vm.approve('Instructor')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.action === 'add' ? 'Approve' : 'Acknowledge')+" for Instructor")])],1):_vm._e(),(_vm.isRecords && !_vm.advisorApproved)?_c('v-list-item',{on:{"click":function($event){return _vm.approve('Advisor')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.action === 'add' ? 'Approve' : 'Acknowledge')+" for Advisor")])],1):_vm._e()],1)],1):_vm._e()]:_vm._e(),(_vm.pendingApproval && _vm.gradeRequired && _vm.isInstructor)?_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-check-circle")]),_vm._v(" Acknowledge and Assign Grade ")],1)]}}],null,false,1208617211),model:{value:(_vm.gradeDialog),callback:function ($$v) {_vm.gradeDialog=$$v},expression:"gradeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Assign Grade for Dropped Class")]),_c('v-card-text',[_c('p',[_vm._v("Choose the grade to assign:")]),_c('v-radio-group',{model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}},[_c('v-radio',{attrs:{"value":"W","label":"W"}}),_c('v-radio',{attrs:{"value":"F","label":"F"}})],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.gradeDialog = false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.grade === '',"color":"success"},on:{"click":function($event){return _vm.approve()}}},[_vm._v("Save and Acknowledge")])],1)],1)],1):(_vm.pendingApproval)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.approve()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-check-circle")]),_vm._v(" "+_vm._s(_vm.action === 'add' ? 'Approve' : 'Acknowledge')+" ")],1):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }